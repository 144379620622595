<template>
  <div :class="containerClass">
    <div class="p-dataview-header" v-if="$slots.header">
      <slot name="header"></slot>
    </div>
    <Divider v-if="isFiltered && getTotalRecords > 0" align="center" class="p-mb-5 p-mt-2">
      {{ getTotalRecords }} Result{{ getTotalRecords !== 1 ? "s" : "" }} found
    </Divider>
    <DVPaginator
      v-if="paginatorTop && $slots.grid && layout === 'grid'"
      :rows="d_rows"
      :first="d_first"
      :totalRecords="getTotalRecords"
      :pageLinkSize="pageLinkSize"
      :template="paginatorTemplate"
      :rowsPerPageOptions="rowsPerPageOptions"
      :currentPageReportTemplate="currentPageReportTemplate"
      :class="{ 'p-paginator-top': paginatorTop }"
      :alwaysShow="alwaysShowPaginator"
      @page="onPage($event)"
    >
      <template #left v-if="$slots.paginatorLeft">
        <slot name="paginatorLeft"></slot>
      </template>
      <template #right v-if="$slots.paginatorRight">
        <slot name="paginatorRight"></slot>
      </template>
    </DVPaginator>
    <div class="p-dataview-content p-d-flex p-flex-column p-flex-1">
      <template v-if="$slots.grid && layout === 'grid'">
        <div class="p-grid">
          <template v-for="(item, index) of items">
            <slot name="grid" :data="item" :index="index"></slot>
          </template>
          <template v-if="!value">
            <template v-for="(_, index) of loadingRows || rows">
              <slot name="grid" :index="index"></slot>
            </template>
          </template>
        </div>
        <div v-if="value && empty" class="p-dataview-emptymessage">
          <slot name="empty"></slot>
        </div>
      </template>
      <template v-else-if="$slots.table && layout === 'table'">
        <slot
          name="table"
          :data="value || Array(loadingRows).fill({})"
          v-if="!empty || !value"
        ></slot>
        <div v-else-if="empty" class="p-dataview-emptymessage">
          <slot name="empty"></slot>
        </div>
      </template>
    </div>
    <DVPaginator
      v-if="paginatorBottom && $slots.grid && layout === 'grid'"
      :rows="d_rows"
      :first="d_first"
      :totalRecords="getTotalRecords"
      :pageLinkSize="pageLinkSize"
      :template="paginatorTemplate"
      :rowsPerPageOptions="rowsPerPageOptions"
      :currentPageReportTemplate="currentPageReportTemplate"
      :class="{ 'p-paginator-bottom': paginatorBottom }"
      :alwaysShow="alwaysShowPaginator"
      @page="onPage($event)"
    >
      <template #left v-if="$slots.paginatorLeft">
        <slot name="paginatorLeft"></slot>
      </template>
      <template #right v-if="$slots.paginatorRight">
        <slot name="paginatorRight"></slot>
      </template>
    </DVPaginator>
    <div class="p-dataview-footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import { resolveFieldData } from '@primeuix/utils/object';
import Paginator from "primevue/paginator";
import Divider from "primevue/divider";

export default {
  name: "DataView",
  components: {
    DVPaginator: Paginator,
    Divider,
  },
  emits: ["update:first", "update:rows", "page"],
  props: {
    value: {
      type: Array,
      default: null,
    },
    layout: {
      type: String,
      default: "grid",
    },
    rows: {
      type: Number,
      default: 0,
    },
    first: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    paginatorPosition: {
      type: String,
      default: "bottom",
    },
    alwaysShowPaginator: {
      type: Boolean,
      default: false,
    },
    paginatorTemplate: {
      type: String,
      default: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    },
    pageLinkSize: {
      type: Number,
      default: 5,
    },
    rowsPerPageOptions: {
      type: Array,
      default: null,
    },
    currentPageReportTemplate: {
      type: String,
      default: "({currentPage} of {totalPages})",
    },
    sortField: {
      type: [String, Function],
      default: null,
    },
    sortOrder: {
      type: Number,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    loadingRows: {
      type: Number,
      default: null,
    },
    pageStateKey: {
      type: String,
      default: "DataView.page",
    },
    isFiltered: Boolean,
  },
  data() {
    return {
      d_first: JSON.parse(sessionStorage.getItem(this.pageStateKey))?.first || this.first,
      d_rows: JSON.parse(sessionStorage.getItem(this.pageStateKey))?.rows || this.rows,
    };
  },
  watch: {
    first(newValue) {
      this.d_first = newValue;
    },
    rows(newValue) {
      this.d_rows = newValue;
    },
    sortField() {
      this.resetPage();
    },
    sortOrder() {
      this.resetPage();
    },
  },
  methods: {
    onPage(event) {
      this.d_first = event.first;
      this.d_rows = event.rows;

      this.$emit("update:first", this.d_first);
      this.$emit("update:rows", this.d_rows);
      this.$emit("page", event);

      sessionStorage.setItem(
        this.pageStateKey,
        JSON.stringify({
          first: event.first,
          rows: event.rows,
        }),
      );
    },
    sort() {
      if (this.value) {
        const value = [...this.value];

        value.sort((data1, data2) => {
          let value1 = resolveFieldData(data1, this.sortField);
          let value2 = resolveFieldData(data2, this.sortField);
          let result = null;

          if (value1 == null && value2 != null) result = -1;
          else if (value1 != null && value2 == null) result = 1;
          else if (value1 == null && value2 == null) result = 0;
          else if (typeof value1 === "string" && typeof value2 === "string")
            result = value1.localeCompare(value2, undefined, { numeric: true });
          else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

          return this.sortOrder * result;
        });

        return value;
      } else {
        return null;
      }
    },
    resetPage() {
      this.d_first = 0;
      this.$emit("update:first", this.d_first);

      sessionStorage.removeItem(this.pageStateKey);
    },
  },
  computed: {
    containerClass() {
      return [
        "p-dataview p-component p-d-flex p-flex-column p-flex-1",
        {
          "p-dataview-table": this.layout === "table",
          "p-dataview-grid": this.layout === "grid",
        },
      ];
    },
    getTotalRecords() {
      if (this.totalRecords) return this.totalRecords;
      else return this.value ? this.value.length : 0;
    },
    empty() {
      return !this.value || this.value.length === 0;
    },
    paginatorTop() {
      return (
        this.paginator && (this.paginatorPosition !== "bottom" || this.paginatorPosition === "both")
      );
    },
    paginatorBottom() {
      return (
        this.paginator && (this.paginatorPosition !== "top" || this.paginatorPosition === "both")
      );
    },
    items() {
      if (this.value && this.value.length) {
        let data = this.value;

        if (data && data.length && this.sortField) {
          data = this.sort();
        }

        if (this.paginator) {
          const first = this.lazy ? 0 : this.d_first;
          return data.slice(first, first + this.d_rows);
        } else {
          return data;
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.p-dataview-header {
  padding: calc(var(--card-padding) / 2) 0 var(--gutter);
}
.p-dataview-emptymessage {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.p-dataview-footer {
  display: flex;
  justify-content: end;
  margin-top: var(--grid-gutter);
  padding-top: var(--gutter);
  border-top: 1px solid;
  border-image: var(--border-gradient);
  border-image-slice: 1 0 0;
}
</style>
